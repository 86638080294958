import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useParams } from 'next/navigation'

import newsAPI from '@Services/news-api'

export const fetchArticle = async (urlkey: string) => {
  const response = await newsAPI.get(urlkey)
  const { data } = response
  return data.article
}

type SuccessResponse = Awaited<ReturnType<typeof fetchArticle>>
type ErrorResponse = unknown

type ArticleQueryOptions = Omit<
  UseQueryOptions<SuccessResponse, ErrorResponse, SuccessResponse, string[]>,
  'queryKey' | 'queryFn'
>

export const useArticleQuery = (options?: ArticleQueryOptions) => {
  const params = useParams()
  const urlkey = params?.urlkey as string

  return useQuery({
    queryKey: ['article', urlkey],
    queryFn: () => fetchArticle(urlkey),
    ...options
  })
}
