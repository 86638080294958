'use client'

import { ReactNode } from 'react'

import usePageView from '@Shared/hooks/use-page-view'

import '@Shared/helpers/polyfills'

type RootTemplateProps = {
  children: ReactNode
}

const RootTemplate = ({ children }: RootTemplateProps) => {
  usePageView()

  return children
}

export default RootTemplate
