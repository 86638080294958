import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
import { useParams } from 'next/navigation'
import { useEffect } from 'react'

import { MOVIE } from '@Shared/constants/page-name'
import { viewEvent } from '@Shared/helpers/tracking'

import { PageView, PageViewSpectacle } from '@Schemas/application/tracking'

import tracking from '@Services/tracking'

import useCity from '../use-city'
import useMovieQuery from '../use-movie-query'
import { usePageName } from '../use-page-name'
import useUserData from '../use-user-data'

dayjs.extend(utc)

export const useMoviePageView = (pageViewData: PageView) => {
  const { city } = useCity()
  const params = useParams()
  const urlKey = params?.urlkey as string

  const pageName = usePageName()
  const { user, data: userAccountData } = useUserData()
  const isUserLogged = Boolean(user?.token)

  const isEnabled =
    pageName === MOVIE && (isUserLogged ? Boolean(userAccountData) : true)

  const { data: movie, isLoading: isMovieLoading } = useMovieQuery({
    urlKey,
    options: {
      enabled: isEnabled
    }
  })

  useEffect(() => {
    if (!isMovieLoading && movie && isEnabled) {
      const eventDate = movie?.premiereDate?.localDate
        ? dayjs?.utc(movie?.premiereDate?.localDate)
        : ''

      const formattedDate =
        eventDate && eventDate.isValid() ? eventDate.format('DD/MM/YYYY') : ''

      const pageData: PageViewSpectacle = {
        id: movie?.id,
        name: movie?.title,
        contentRating: movie?.contentRating,
        distributor: movie?.distributor,
        genres: movie?.genres?.join(', '),
        directedBy: movie?.director,
        countries: movie?.countryOrigin,
        runtime: movie?.duration,
        openingDate: formattedDate,
        year: movie?.premiereDate?.year,
        trailerUrl: movie?.trailers[0]?.url,
        tag: movie?.tags.join(', ')
      }

      pageViewData.spectacle = pageData
      tracking.pageView(pageViewData)

      viewEvent('view_item', [
        {
          item_name: movie?.title,
          item_id: movie?.id,
          item_brand: movie?.distributor
        }
      ])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMovieLoading, isEnabled, city, urlKey])
}
