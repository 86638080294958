'use client'

import { useParams, usePathname, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

import {
  CUSTOM_PAGE_VIEW_PAGES,
  EVENTS_EVENT_TYPE,
  MOVIE,
  THEATER
} from '@Shared/constants/page-name'

import { PageView } from '@Schemas/application/tracking'
import { UserPageView } from '@Schemas/application/user'

import tracking from '@Services/tracking'

import { useArticlePageView } from '../use-article-page-view'
import useCity from '../use-city'
import { useMoviePageView } from '../use-movie-page-view'
import { usePageName } from '../use-page-name'
import usePartnership from '../use-partnership'
import { useTheaterPageView } from '../use-theater-page-view'
import useUserData from '../use-user-data'

const usePageView = () => {
  const partnership = usePartnership()
  const { city } = useCity()

  const { user, data: userAccountData } = useUserData()
  const isUserLogged = Boolean(user?.token)

  const pathname = usePathname()
  const pageName = usePageName()

  const params = useParams()
  const urlKey = decodeURIComponent(params?.urlkey as string)

  const searchParams = useSearchParams()
  const partnershipQuery = searchParams?.get('partnership')

  const url = process.env.BASE_URL + pathname
  const showtimes = pageName === MOVIE || pageName === THEATER

  const isCustomPageViewPage = CUSTOM_PAGE_VIEW_PAGES.includes(pageName)

  const userData: UserPageView = {
    id: user?.id,
    name: user?.name as string,
    email: userAccountData?.email,
    cpf: userAccountData?.account?.cpf
  }

  const pageViewData: PageView = {
    event: 'pageview',
    page: {
      name: pageName,
      type: 'discovery',
      state: city?.uf,
      cityID: city?.id,
      city: city?.name,
      partnership: partnershipQuery || partnership?.id,
      path: pathname,
      url: url,
      showtimes: showtimes,
      event_type: pageName === EVENTS_EVENT_TYPE ? urlKey : ''
    },
    user: userAccountData
      ? userData
      : {
          name: 'guest'
        }
  }

  useMoviePageView(pageViewData)

  useTheaterPageView(pageViewData)

  useArticlePageView(pageViewData)

  useEffect(() => {
    if (isUserLogged && !userAccountData) return
    if (isCustomPageViewPage) return

    tracking.pageView(pageViewData)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName, userAccountData, city])
}

export default usePageView
