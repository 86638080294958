import axios from 'axios'

const baseURL =
  typeof window === 'undefined' ? process.env.ARTICLES_URL : '/api/articles'

const newsAPI = axios.create({
  baseURL: baseURL
})

export default newsAPI
