import { useQuery } from '@tanstack/react-query'
import { useParams } from 'next/navigation'

import contentAPI from '@Services/content-api'

import usePartnership from '../use-partnership'

const fetchMovie = async (urlKey: string, partnershipId: string) => {
  const response = await contentAPI.get(
    `/v0/events/url-key/${urlKey}/partnership/${partnershipId}`
  )

  const { data } = response
  return data
}

type MovieQueryParams = {
  urlKey?: string
  options?: any
}

const useMovieQuery = (args?: MovieQueryParams) => {
  const params = useParams()
  const partnership = usePartnership()
  const urlkey = args?.urlKey || (params?.urlkey as string)

  return useQuery<any>({
    queryKey: ['movie', urlkey, partnership?.id],
    queryFn: () => fetchMovie(urlkey, partnership?.id),
    ...args?.options
  })
}

export default useMovieQuery
