import { useEffect } from 'react'

import { ARTICLE } from '@Shared/constants/page-name'

import { PageView, PageViewArticle } from '@Schemas/application/tracking'

import tracking from '@Services/tracking'

import { useArticleQuery } from '../use-article-query'
import useCity from '../use-city'
import { usePageName } from '../use-page-name'
import useUserData from '../use-user-data'

export const useArticlePageView = (pageViewData: PageView) => {
  const { city } = useCity()

  const { user, data: userAccountData } = useUserData()
  const isUserLogged = Boolean(user?.token)

  const pageName = usePageName()

  const isEnabled =
    pageName === ARTICLE && (isUserLogged ? Boolean(userAccountData) : true)

  const { data: article, isLoading: isArticleLoading } = useArticleQuery({
    enabled: isEnabled
  })

  useEffect(() => {
    if (!isArticleLoading && article && isEnabled) {
      const pageData: PageViewArticle = {
        title: article?.title,
        subtitle: article?.subtitle,
        imageUrl: article?.image?.url
      }

      pageViewData.article = pageData
      tracking.pageView(pageViewData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArticleLoading, isEnabled, city])
}
