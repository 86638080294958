import { useParams } from 'next/navigation'
import { useEffect } from 'react'

import { THEATER } from '@Shared/constants/page-name'

import { PageView, PageViewPlace } from '@Schemas/application/tracking'

import tracking from '@Services/tracking'

import { usePageName } from '../use-page-name'
import { useTheaterQuery } from '../use-theater-query'
import useUserData from '../use-user-data'

export const useTheaterPageView = (pageViewData: PageView) => {
  const params = useParams()
  const urlKey = params?.urlkey as string

  const { user, data: userAccountData } = useUserData()
  const isUserLogged = Boolean(user?.token)

  const pageName = usePageName()

  const isEnabled =
    pageName === THEATER && (isUserLogged ? Boolean(userAccountData) : true)

  const { data: theater, isLoading: isTheaterLoading } = useTheaterQuery({
    urlKey,
    options: {
      enabled: isEnabled
    }
  })

  useEffect(() => {
    if (!isTheaterLoading && theater && isEnabled) {
      const pageData: PageViewPlace = {
        id: theater?.id,
        name: theater?.name,
        exhibitorId: theater?.corporationId,
        exhibitor: theater?.corporation,
        neighborhood: theater?.neighborhood
      }

      pageViewData.place = pageData
      tracking.pageView(pageViewData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTheaterLoading, isEnabled])
}
